import { Input, InputProps } from "@heroui/input";
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

type Props = {
    name: string;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    inputProps?: Pick<
        InputProps,
        | 'variant'
        | 'color'
        | 'size'
        | 'radius'
        | 'label'
        | 'placeholder'
        | 'startContent'
        | 'endContent'
        | 'fullWidth'
        | 'isClearable'
        | 'isReadOnly'
        | 'classNames'
        | 'className'
        | 'disableAnimation'
        | 'isRequired'
        | 'isDisabled'
        | 'labelPlacement'
        | 'type'
        | 'autoComplete'
    >;
};

const RHFInput = (props: Props) => {
    const { name, rules, inputProps } = props;
    const {
        control,
        formState: { disabled },
    } = useFormContext();
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            disabled={disabled}
            render={({
                field: { name, onBlur, onChange, ref, value, disabled },
                formState: { isLoading, isSubmitting },
                fieldState: { invalid, isValidating, error },
            }) => {
                return (
                    <Input
                        {...inputProps}
                        ref={ref}
                        name={name}
                        onBlur={onBlur}
                        onValueChange={onChange}
                        value={value}
                        isInvalid={invalid}
                        isDisabled={disabled || isLoading || isSubmitting || inputProps?.isDisabled}
                        errorMessage={error?.message || ''}
                    />
                );
            }}
        />
    );
};

export default RHFInput;
