import { cookieStorageKeys } from '@/constants/storageKeys';
import { getClientCookie, removeClientCookie } from '@/utils/client-cookie';
import type { BaseResponse, EnhanceRequestInit, ResponseWithData } from './types/fetch.types';

const TIMEOUT = 30000;

interface FetchInterface {
    run<ResponseGeneric = any>(
        url: RequestInfo,
        config?: EnhanceRequestInit,
    ): Promise<ResponseWithData<ResponseGeneric>>;
}

class FetcherError extends Error {
    status: number;
    info: any;
    constructor(message: string, status: number, info: any) {
        super(message);
        this.name = this.constructor.name;
        this.status = status;
        this.info = info;
    }
}

class BasicFetcher implements FetchInterface {
    async run<ResponseGeneric>(
        url: RequestInfo,
        config?: EnhanceRequestInit,
    ): Promise<ResponseWithData<ResponseGeneric>> {
        let accessToken: string = '';
        const isClientSide = !(typeof window === 'undefined');
        if (isClientSide) {
            accessToken = getClientCookie(cookieStorageKeys.END_USER_AUTH_KEY) || '';
        }

        const response = await fetch(url, {
            ...config,
            headers: {
                ...config?.headers,
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                ClientId: process.env.NEXT_PUBLIC_CLIENT_ID as string,
                ClientSecret: process.env.NEXT_PUBLIC_CLIENT_SECRET as string,
                Authorization: accessToken ? `Bearer ${accessToken}` : '',
                aid: process.env.NEXT_PUBLIC_AID as string,
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                if (isClientSide) {
                    removeClientCookie({
                        name: cookieStorageKeys.END_USER_AUTH_KEY,
                        options: {
                            domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                        },
                    });
                    window.location.href = '/login';
                }
            }
            // return response;
            const errorInfo = await response?.json?.();

            const error = new FetcherError(
                errorInfo?.Message || 'An error occurred while fetching the data.',
                response.status,
                '',
            );

            return errorInfo;
        }

        return response;
    }
}

class JsonFetcherDecorator implements FetchInterface {
    private decoratee: FetchInterface;

    constructor(decoratee: FetchInterface) {
        this.decoratee = decoratee;
    }

    async run<ResponseGeneric>(
        url: RequestInfo,
        config?: EnhanceRequestInit,
    ): Promise<ResponseWithData<ResponseGeneric>> {
        const response = await this.decoratee.run(url, config);

        const json = (await response.json()) as BaseResponse<ResponseGeneric>;
        response.data = json;
        return response;

        // try {
        //     JSON.stringify(response);
        //     return response;
        // } catch (e) {
        //     const json = (await response.json()) as BaseResponse<ResponseGeneric>;
        //     response.data = json;
        //     return response;
        // }
    }
}

class TimeoutFetcher implements FetchInterface {
    private decoratee: FetchInterface;

    constructor(decoratee: FetchInterface) {
        this.decoratee = decoratee;
    }

    async run<ResponseGeneric>(
        url: RequestInfo,
        config?: EnhanceRequestInit,
    ): Promise<ResponseWithData<ResponseGeneric>> {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), TIMEOUT);
        const response = await this.decoratee.run(url, {
            signal: controller.signal,
            ...config,
        });
        clearTimeout(id);
        return response;
    }
}

const fetcher = new TimeoutFetcher(new JsonFetcherDecorator(new BasicFetcher()));

const fetchAPI = {
    query: <RequestGeneric extends Record<string, any>, ResponseGeneric>({
        url,
        method = 'GET',
        request,
        config,
    }: {
        url: string;
        method?: 'GET';
        request?: RequestGeneric;
        config?: EnhanceRequestInit;
    }) =>
        fetcher.run.bind(fetcher)<ResponseGeneric>(`${url}`, {
            method: method,
            cache: 'default',
            next: {
                revalidate: 30,
            },
            ...config,
        }),
    mutation: <RequestGeneric, ResponseGeneric>({
        url,
        method,
        request,
        config,
    }: {
        url: string;
        method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
        request: RequestGeneric;
        config?: EnhanceRequestInit;
    }) => {
        return fetcher.run.bind(fetcher)<ResponseGeneric>(url, {
            method: method,
            body: request ? JSON.stringify(request) : undefined,
            ...(config ? config : {}),
        });
    },
};

export default fetchAPI;
