'use client';
import {useLoginMutation} from '@/apis/auth/login';
import axios from '@/apis/axiosInstance';
import {cookieStorageKeys} from '@/constants/storageKeys';
import {useCustomRouter} from '@/hooks/useCustomRouter';
import {ISignInForm} from '@/models/Auth/AuthForm';
import {LoginResponse} from '@/models/Auth/AuthResponse';
import {CustomerModel} from '@/models/Customer/CustomerModel';
import {TenantItem} from '@/models/Tenant/TenantResponse';
import {removeClientCookie, setClientCookie} from '@/utils/client-cookie';
import {isEmail} from '@/utils/regex';
import {Button} from '@heroui/button';
import {addToast} from '@heroui/toast';
import {FaRegEyeSlash} from '@react-icons/all-files/fa/FaRegEyeSlash';
import {IoEyeOutline} from '@react-icons/all-files/io5/IoEyeOutline';
import {useTranslations} from 'next-intl';
import {useSearchParams} from 'next/navigation';
import {FC, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import RHFInput from '../common/react-hook-form/RHFInput';
import AuthGoogleAndFaceBook from './AuthGoogleAndFaceBook';

interface IProps {
    isFulfillmentLogin?: boolean;
}

const LoginForm: FC<IProps> = (props) => {
    //#region props
    const {isFulfillmentLogin} = props;
    //#endregion
    //#region hooks
    const t = useTranslations('auth');
    const tValidation = useTranslations('validation');
    const tCommon = useTranslations('common');
    const {push, replace} = useCustomRouter();
    const searchParams = useSearchParams();
    //#endregion

    //#region states
    const [isVisible, setIsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    //#endregion

    //#region form
    const hookForm = useForm<ISignInForm>({
        mode: 'onChange',
        reValidateMode: 'onSubmit',
        resolver: undefined,
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        defaultValues: {
            Username: '',
            Password: '',
        },
    });
    //#endregion

    //#region services
    const {trigger: login, isMutating: isLoadingLogging, error, data: loginData} = useLoginMutation();
    //#endregion

    //#region handlers
    const handleSelectTenant = async (AccessToken: string) => {
        return await axios<
            any,
            {
                data: {
                    Data: LoginResponse;
                };
            }
        >({
            url: `${process.env.NEXT_PUBLIC_API_URL}/api/account/select-tenant`,
            method: 'GET',
            headers: {
                ClientId: process.env.NEXT_PUBLIC_CMS_CLIENT_ID as string,
                ClientSecret: process.env.NEXT_PUBLIC_CMS_CLIENT_SECRET as string,
                Authorization: `Bearer ${AccessToken}`,
            },
        });
    };
    const handleGetTenant = async (AccessToken: string) => {
        return await axios<
            any,
            {
                data: {
                    Data: TenantItem;
                };
            }
        >({
            url: `${process.env.NEXT_PUBLIC_API_URL}/api/tenant/get-current`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${AccessToken}`,
            },
        });
    };
    const handleGetCustomer = async (AccessToken: string) => {
        return await axios<
            any,
            {
                data: {
                    Data: CustomerModel;
                };
            }
        >({
            url: `${process.env.NEXT_PUBLIC_API_URL}/api/customer/get-by-token`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${AccessToken}`,
            },
        });
    };
    const onSubmit = async (data: ISignInForm) => {
        try {
            setIsLoading(true);
            const response = await login({
                UserName: data.Username,
                Password: data.Password,
                TenantCode: process.env.NEXT_PUBLIC_TENANT_CODE as string,
                AId: process.env.NEXT_PUBLIC_AID as string,
            });

            if (response?.data?.Code === -10) {
                addToast({
                    color: 'danger',
                    title: tCommon('errorToastTitle'),
                    description: 'Tài khoản không tồn tại',
                });
                return;
                // if (isFulfillmentLogin) {
                //     return push(`/register-fulfillment?UserName=${data?.Username}`, {
                //         preserveQuery: true,
                //     });
                // } else {
                //     return push(`/register?UserName=${data?.Username}`, {
                //         preserveQuery: true,
                //     });
                // }
            }

            if (response?.data?.Code === -13) {
                addToast({
                    color: 'danger',
                    title: tCommon('errorToastTitle'),
                    description: 'Tài khoản chưa được kích hoạt',
                });
                if (isFulfillmentLogin) {
                    return push(`/register-fulfillment?UserName=${data?.Username}`, {
                        preserveQuery: true,
                    });
                } else {
                    return push(`/register?UserName=${data?.Username}`, {
                        preserveQuery: true,
                    });
                }
            }

            if (response?.data?.Code === -14) {
                addToast({
                    color: 'danger',
                    title: tCommon('errorToastTitle'),
                    description: 'Sai tên đăng nhập hoặc mật khẩu',
                });
                return;
            }

            if (response?.data?.Code === -47) {
                addToast({
                    color: 'danger',
                    title: tCommon('errorToastTitle'),
                    description: 'Đăng nhập sai quá 5 lần, tài khoản bị khoá trong 60 phút',
                });
                return;
            }

            if (response?.data?.Code === -27) {
                addToast({
                    color: 'danger',
                    title: tCommon('errorToastTitle'),
                    description: 'Tài khoản không phải quản trị viên',
                });
                return;
            }

            if (response?.data?.Data) {
                const result = response?.data?.Data;

                const {AccessToken: EndUserAccessToken, RefreshToken: EndUserRefreshToken} = result;

                if (isFulfillmentLogin) {
                    const selectTenantResponse = await handleSelectTenant(EndUserAccessToken);

                    if (selectTenantResponse?.data?.Data) {
                        const {AccessToken: CMSAccessToken, RefreshToken: CMSRefreshToken} =
                            selectTenantResponse?.data?.Data;

                        const [tenant, customer] = await Promise.all([
                            handleGetTenant(selectTenantResponse?.data?.Data?.AccessToken),
                            handleGetCustomer(selectTenantResponse?.data?.Data?.AccessToken),
                        ]);

                        setClientCookie({
                            name: cookieStorageKeys.END_USER_AUTH_KEY,
                            value: EndUserAccessToken,
                            options: {
                                domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                            },
                        });

                        setClientCookie({
                            name: cookieStorageKeys.CMS_AUTH_KEY,
                            value: CMSAccessToken,
                            options: {
                                domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                            },
                        });

                        setClientCookie({
                            name: cookieStorageKeys.CMS_AUTH_REFRESH,
                            value: CMSRefreshToken,
                            options: {
                                domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                            },
                        });

                        if (customer?.data?.Data) {
                            setClientCookie({
                                name: cookieStorageKeys.CMS_CUSTOMER_KEY,
                                value: customer?.data?.Data?.Id || '',
                                options: {
                                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                                },
                            });
                        }

                        if (tenant?.data?.Data) {
                            setClientCookie({
                                name: cookieStorageKeys.CMS_TENANT_KEY,
                                value: JSON.stringify({
                                    Id: tenant?.data?.Data?.Id,
                                    Code: tenant?.data?.Data?.Code,
                                    Name: tenant?.data?.Data?.Name,
                                }),
                                options: {
                                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                                },
                            });
                        }

                        // const returnUrl =
                        //     searchParams.toString().split('returnUrl=')[1] || searchParams.get('returnUrl') || '';

                        // if (Boolean(returnUrl)) {
                        //     push(decodeURIComponent(returnUrl));
                        // } else {
                        push((process.env.NEXT_PUBLIC_FULFILLMENT_URL as string) || '/');
                        // }
                    } else {
                        throw new Error('Login failed');
                    }
                } else {
                    setClientCookie({
                        name: cookieStorageKeys.END_USER_AUTH_KEY,
                        value: EndUserAccessToken,
                        options: {
                            domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                        },
                    });

                    const returnUrl =
                        searchParams.toString().split('returnUrl=')[1] || searchParams.get('returnUrl') || '';

                    if (Boolean(returnUrl)) {
                        push(decodeURIComponent(returnUrl));
                    } else {
                        replace('/');
                    }
                }
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            removeClientCookie({
                name: cookieStorageKeys.END_USER_AUTH_KEY,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            removeClientCookie({
                name: cookieStorageKeys.CMS_TENANT_KEY,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            removeClientCookie({
                name: cookieStorageKeys.CMS_WORKGROUP_KEY,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            removeClientCookie({
                name: cookieStorageKeys.CMS_AUTH_KEY,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            removeClientCookie({
                name: cookieStorageKeys.CMS_AUTH_REFRESH,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            removeClientCookie({
                name: cookieStorageKeys.CMS_CUSTOMER_KEY,
                options: {
                    domain: cookieStorageKeys.SET_COOKIE_DOMAIN,
                },
            });
            addToast({
                color: 'danger',
                title: tCommon('errorToastTitle'),
                description: tCommon('errorToastMessage'),
            });
        } finally {
            setIsLoading(false);
        }
    };
    //#endregion

    return (
        <FormProvider {...hookForm}>
            <form className='flex flex-col gap-4' onSubmit={hookForm.handleSubmit(onSubmit)}>
                <RHFInput
                    inputProps={{
                        label: t('loginForm.labelUserName'),
                        placeholder: t('loginForm.labelUserName'),
                        variant: 'bordered',
                        labelPlacement: 'outside',
                        isRequired: true,
                        autoComplete: 'new-password',
                        classNames: {
                            label: 'font-bold',
                        },
                    }}
                    name='Username'
                    rules={{
                        required: tValidation('email.required'),
                        validate: (value) => {
                            return isEmail(value) || tValidation('email.invalid');
                        },
                    }}
                />
                <div className='relative'>
                    <span
                        className='absolute top-0 right-0 uppercase underline decoration-solid text-xs text-primary cursor-pointer'
                        onClick={() => {
                            if (isFulfillmentLogin) {
                                push('/forgot-password-fulfillment', {
                                    preserveQuery: true,
                                });
                            } else {
                                push('/forgot-password', {
                                    preserveQuery: true,
                                });
                            }
                        }}
                        tabIndex={-1}
                    >
                        {t('loginForm.forgotPassword')}
                    </span>
                    <RHFInput
                        inputProps={{
                            label: t('loginForm.labelPassword'),
                            placeholder: t('loginForm.labelPassword'),
                            variant: 'bordered',
                            labelPlacement: 'outside',
                            isRequired: true,
                            autoComplete: 'new-password',
                            classNames: {
                                label: 'font-bold',
                            },
                            endContent: (
                                <button
                                    className='focus:outline-none'
                                    type='button'
                                    onClick={() => setIsVisible(!isVisible)}
                                    aria-label='toggle password visibility'
                                    tabIndex={-1}
                                >
                                    {isVisible ? <IoEyeOutline/> : <FaRegEyeSlash/>}
                                </button>
                            ),
                            type: isVisible ? 'text' : 'password',
                        }}
                        name='Password'
                        rules={{
                            required: tValidation('password.required'),
                            minLength: {
                                value: 6,
                                message: tValidation('password.minLength'),
                            },
                            // maxLength: {
                            //     value: 20,
                            //     message: tValidation('password.maxLength'),
                            // },
                        }}
                    />
                </div>

                <Button color='primary' type='submit' fullWidth isLoading={isLoadingLogging || isLoading}>
                    {t('button.login')}
                </Button>

                <AuthGoogleAndFaceBook/>
                <p className='text-center text-sm md:text-base'>
                    {t('button.noAccount')}
                    <span
                        onClick={() => {
                            if (isFulfillmentLogin) {
                                push('/register-fulfillment', {
                                    preserveQuery: true,
                                });
                            } else {
                                push('/register', {
                                    preserveQuery: true,
                                });
                            }
                        }}
                        className='ps-1 underline decoration-solid cursor-pointer text-primary'
                    >
                        {t('button.register')}
                    </span>
                </p>
            </form>
        </FormProvider>
    );
};
export default LoginForm;
