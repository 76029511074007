import { LoginRequest } from '@/models/Auth/AuthRequest';
import { LoginResponse } from '@/models/Auth/AuthResponse';
import { BaseAxiosResponseGetAll } from '@/models/globalInterface';
import { useMutation } from '../swrHooks';

export const useLoginMutation = () => {
    return useMutation<LoginRequest, BaseAxiosResponseGetAll<LoginResponse>>({
        method: 'POST',
        url: `${process.env.NEXT_PUBLIC_API_URL}/api/account/login-customer`,
        // url: `${process.env.NEXT_PUBLIC_API_URL}/api/account/login-by-aid`,
    });
};
