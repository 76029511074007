import { cookieStorageKeys } from '@/constants/storageKeys';
import { getClientCookie } from '@/utils/client-cookie';
import axiosBase, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

export type AxiosRequestType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const accessControlAllowOrigin = {
    'Access-Control-Allow-Origin': '*',
};

let axios: AxiosInstance = axiosBase.create({
    baseURL: '',
    withCredentials: false, // to use cookies
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${getClientCookie(cookieStorageKeys.END_USER_AUTH_KEY)}`,
        timeout: 30000,
        ...accessControlAllowOrigin,
    },
});

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        if (!config?.headers) {
            throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
        }

        return { ...config, ...accessControlAllowOrigin };
    },
    (error: any) => Promise.reject(error),
);

axios.interceptors.response.use(
    (axiosResponse) => {
        return axiosResponse;
    },
    async (err: any) => {
        const error = err.response;
        // if error is 401
        if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
        }
    },
);

export default axios;
