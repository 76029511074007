'use client';

import useSWR from 'swr';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';
import fetchAPI from './fetchApi';
import { EnhanceRequestInit, ResponseWithData } from './types/fetch.types';

const useQuery = <RequestGeneric extends Record<string, any>, ResponseGeneric>({
    url,
    method,
    request,
    fetcherConfig,
}: {
    url: string;
    method: 'GET';
    request?: RequestGeneric;
    fetcherConfig?: EnhanceRequestInit;
}) => {
    return useSWR(
        url,
        async () =>
            await fetchAPI.query<RequestGeneric, ResponseGeneric>({
                url,
                request,
                config: fetcherConfig,
                method: method,
            }),
        {
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateOnFocus: false,
            dedupingInterval: 5000,
            // refreshInterval: 3000,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
            revalidateIfStale: true,
            shouldRetryOnError: false,
            errorRetryCount: 0,
            // keepPreviousData: true,
            // suspense: true,
            // fallbackData: {},
            loadingTimeout: 120000,
        },
    );
};

const useMutation = <RequestGeneric, ResponseGeneric>({
    url,
    method,
    // request,
    mutationOptions,
    fetcherConfig,
}: {
    url: string;
    method: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
    // request?: RequestGeneric;
    mutationOptions?: SWRMutationConfiguration<ResponseWithData<ResponseGeneric>, Error>;
    fetcherConfig?: EnhanceRequestInit;
}) => {
    return useSWRMutation(
        url,
        async (key: string, { arg }: { arg: RequestGeneric }) =>
            fetchAPI.mutation<RequestGeneric, ResponseGeneric>({
                url,
                request: arg,
                config: fetcherConfig,
                method: method,
            }),
        mutationOptions,
    );

    // // let currentArgsRef = useRef<RequestGeneric | null>(null);

    // // Wrapper function to access args
    // const customTrigger = async (key: string, { arg }: { arg: RequestGeneric }) => {
    //     // currentArgsRef.current = arg;
    //     return fetchAPI.mutation<RequestGeneric, ResponseGeneric>({
    //         url,
    //         request: arg,
    //         config: fetcherConfig,
    //         method: method,
    //     });
    // };
    // const { trigger, isMutating, data, error, reset } = useSWRMutation(
    //     url,
    //     // customTrigger,
    //     async (key: string, { arg }: { arg: RequestGeneric }) => {
    //         // currentArgsRef.current = arg;
    //         return fetchAPI.mutation<RequestGeneric, ResponseGeneric>({
    //             url,
    //             request: arg,
    //             config: fetcherConfig,
    //             method: method,
    //         });
    //     },
    //     mutationOptions,
    // );

    // // Provide a function to access the arguments
    // // const getCurrentArgs = () => currentArgsRef.current;

    // return { trigger, isMutating, data, error, reset };
    // return { trigger, isMutating, data, error, reset, getCurrentArgs };
};

export { useMutation, useQuery };
