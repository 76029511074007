import { Button } from "@heroui/button";
import { useTranslations } from 'next-intl';
import { Fragment } from 'react';
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FcGoogle } from '@react-icons/all-files/fc/FcGoogle';
const AuthGoogleAndFaceBook = () => {
    const t = useTranslations('auth');
    return (
        <Fragment>
            <p className='text-center text-gray-500 text-sm md:text-base'>{t('loginForm.optionSignIn')}</p>
            <div className='flex flex-col md:flex-row gap-2 justify-between items-center pb-4'>
                <Button
                    disabled
                    // variant='ghost'
                    className='font-bold'
                    fullWidth
                    startContent={<FcGoogle size={'1em'} />}
                >
                    Google
                </Button>
                <Button
                    disabled
                    className='font-bold'
                    fullWidth
                    startContent={<FaFacebook color='blue' size={'1em'} />}
                >
                    Facebook
                </Button>
            </div>
        </Fragment>
    );
};
export default AuthGoogleAndFaceBook;
